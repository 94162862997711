import $ from 'jquery'

const $doc = $(document)
const $openSearch = $('.js-search-open-close')
const $searchForm = $('.search-form')
const $header = $('.js-header')

function showHeaderSearch(event){
  event.preventDefault()
  $openSearch.toggleClass('is-active')
  $searchForm.toggleClass('is-active')
  $header.toggleClass('search-is-active')
  if ( $searchForm.hasClass('is-active')) {
    $openSearch.attr('aria-expanded', 'true')
  } else {
    $openSearch.attr('aria-expanded', 'false')
  }
}

export default function initSearch(){
  $doc.on('click', '.js-search-open-close', showHeaderSearch )
  $doc.on('keydown', function(event) {
    if ( $searchForm.hasClass('is-active') && (event.key == "Escape") ) {
      $openSearch.attr('aria-expanded', 'false')
      $searchForm.toggleClass('is-active')
      $header.toggleClass('search-is-active')
      $openSearch.toggleClass('is-active')
    }
  })
  // $doc.on('focusin', function (event) {
  //   var $target = $(event.target);
  //   if (!$target.closest('.js-header-wrapper').length) {
  //     if ( $searchForm.hasClass('is-active') ) {
  //       $openSearch.attr('aria-expanded', 'false')
  //       $searchForm.toggleClass('is-active')
  //       $openSearch.toggleClass('is-active')
  //     }
  //   }
  // })
}

import $ from 'jquery'

function initMainSlider(){
  if ($('.js-home-slider').length) {
    $('.js-home-slider').slick({
      arrows: false,
      dots: false,
      autoplay: true
    });
  }
  if ($('.js-headlines-slider--mobile').length) {
    $('.js-headlines-slider--mobile').slick({
      arrows: false,
      dots: true,
      speed: 0,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: 'linear',
    });
  }
  if ($('.js-headlines-slider').length) {
    $('.js-headlines-slider').slick({
      arrows: false,
      //dots: true,
      //autoplay: true,
      speed: 0,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: 'linear',
      //asNavFor: '.js-nav-slider',
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            //dots: false,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
    $('.js-headlines-slider2').slick({
      arrows: false,
      dots: false,
      speed: 0,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: 'linear',
    });
    $('.js-headlines-slider2 .slick-slide').removeClass('slick-current');
    $('.js-nav-slider').slick({
      slidesToShow: 12,
      slidesToScroll: 1,
      asNavFor: $('.js-headlines-slider, .js-headlines-slider2'),
      dots: false,
      centerMode: false,
      //focusOnSelect: false
    });
    $('.js-nav-slider .slick-slide').on('click', function (event) {
      let index = $(this).data('slickIndex');
      $('.js-nav-slider .slick-slide').removeClass('slick-current');
      $(this).addClass('slick-current');
      if (index%2==0) {
        $('.js-headlines-slider').slick('slickGoTo', $(this).data('slickIndex'));
        $('.js-headlines-slider2 .slick-slide').removeClass('slick-current');
      } else {
        $('.js-headlines-slider2').slick('slickGoTo', $(this).data('slickIndex'));
        $('.js-headlines-slider .slick-slide').removeClass('slick-current');
      }
    });
  }
  if ($('.js-logos-slider').length) {
    $('.js-logos-slider').slick({
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      infinite: true,
      responsive: [
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }]
    });
  }
}

export default function initSlider(){
  $(document).ready( initMainSlider )
}

import $ from 'jquery'

const resourcesList = $('.resources__list')

function loadMoreNews() {
    const data = {
        'action': 'loadmorenews',
        'query': news_ajax,
        'page' : current_page_news_ajax
    };
 
    $.ajax({
        url : ajax_object.ajax_url,
        data : data,
        type : 'POST',
        beforeSend : function ( xhr ) {
            $('.js-load-more-news').text('Loading...');
        },
        success : function( posts ){
            if( posts ) {

                $('.js-load-more-news').text( 'Load More news' );
                resourcesList.find('ul').append( posts );
                current_page_news_ajax++;

                if ( current_page_news_ajax == max_page_news_ajax ) 
                    $('.js-load-more-news').hide();

            } else {
                $('.js-load-more-news').hide();
            }
        }
    });
    return false;
}


export default function initLoadMoreNews() {
    $(document).on('click', '.js-load-more-news', loadMoreNews )
}
import $ from 'jquery'

const teamList = $('.team__grid');
const teamWrapper = $('.team__grid--wrapper');

function toggleTabs(e) {
    e.preventDefault();
    if(!$(this).hasClass('active')) {
        $(this).addClass('active')
        $('.js-team-filter').not(this).removeClass('active');
    }
    const category = $(this).attr('data-slug');
    const data = {
        action : "filter_team",
        cat : category
    }

    return $.ajax({
        url : ajax_object.ajax_url,
        data : data,
        beforeSend : function(xhr){
            teamList.empty();
            teamWrapper.addClass('loading');
        },
        success : function(response) {
            if( response ) {
                teamList.html(response);
                teamWrapper.removeClass('loading');
            }
        }
    });
}


export default function initTeamFilters() {
    $(document).on('click', '.js-team-filter', toggleTabs )
}
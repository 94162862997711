import $ from 'jquery'

const filterForm = $('.resources__filter-form')
const resourcesListWrapper = $('.resources__list-wrapper')
const resourcesList = $('.resources__list')
const filterModal = $('.resources__filter-form-wrapper');

function filterResources() {
    $.ajax({
        url: `${ajax_object.ajax_url}?${filterForm.serialize()}`,
        data: {action: 'ajaxfilter'},
        dataType : 'json',
        type : 'POST',
        beforeSend : function(xhr){
            resourcesList.empty();
            resourcesListWrapper.addClass('loading');
        },
        success : function( data ){
            current_page_ajax = 1;
            posts_ajax = data.posts;
            max_page_ajax = data.max_page;
            resourcesListWrapper.removeClass('loading');
            resourcesList.html(data.content);
            if ( data.max_page < 2 ) {
                $('.js-load-more').hide();
            } else {
                $('.js-load-more').show();
            }
        }
    });
    filterModalFunc();
}

function loadMore() {
    const data = {
        'action': 'loadmore',
        'query': posts_ajax,
        'page' : current_page_ajax
    };
 
    $.ajax({
        url : ajax_object.ajax_url,
        data : data,
        type : 'POST',
        beforeSend : function ( xhr ) {
            $('.js-load-more').text('Loading...');
        },
        success : function( posts ){
            if( posts ) {

                $('.js-load-more').text( 'Load More Research and Reports' );
                resourcesList.find('ul').append( posts );
                current_page_ajax++;

                if ( current_page_ajax == max_page_ajax ) 
                    $('.js-load-more').hide();

            } else {
                $('.js-load-more').hide();
            }
        }
    });
    return false;
}

function filterModalFunc() {
    filterModal.toggleClass('is-active');
}


export default function initResourceFilters() {
    const filterCheckbox = filterForm.find('input[type=checkbox]');
    $(document).on('change', filterCheckbox, filterResources )
    $(document).on('click', '.js-load-more', loadMore )
    $(document).on('click', '.js-open-filter', filterModalFunc )
    $(document).on('click', '.js-close-filter', filterModalFunc )
}
import $ from 'jquery'

const $doc = $(document)
const $html = $('html')
const $mobileNav = $('.js-mobile-nav')
const $hamburger = $('.js-hamburger')
const $header = $('.js-header')
const $subNav = $('.js-mobile-nav .nav__list > li.menu-item-has-children')

function showMobileNav(event) {
  event.preventDefault()
  $html.toggleClass('inactive-scroll')
  $mobileNav.toggleClass('is-active')
  $hamburger.toggleClass('is-active')
  $header.toggleClass('mobilenav-is-active')
  if ( $mobileNav.hasClass('is-active')) {
    $hamburger.attr('aria-expanded', 'true')
  } else {
    $hamburger.attr('aria-expanded', 'false')
  }
}
function openSubNav(event) {
  event.preventDefault()
  var $target = $(event.target)
  if($target.parent().hasClass('is-active')) {
    $target.parent().removeClass('is-active')
  } else {
    $subNav.removeClass('is-active')
    $target.parent().addClass('is-active')
  }
}

export default function initMobileNav(){
  $doc.on('click', '.js-hamburger', showMobileNav )
  $doc.on('keydown', function(event) {
    if ( $mobileNav.hasClass('is-active') && (event.key == "Escape") ) {
      $hamburger.attr('aria-expanded', 'false')
      $html.toggleClass('inactive-scroll')
      $mobileNav.toggleClass('is-active')
      $hamburger.toggleClass('is-active')
    }
  })
  $doc.on('focusin', function (event) {
    var $target = $(event.target);
    if (!$target.closest('.js-header-wrapper').length) {
      if ( $mobileNav.hasClass('is-active') ) {
        $hamburger.attr('aria-expanded', 'false')
        $html.toggleClass('inactive-scroll')
        $mobileNav.toggleClass('is-active')
        $hamburger.toggleClass('is-active')
      }
    }
  })
  $mobileNav.on('click', '.nav__list > li.menu-item-has-children > a', openSubNav )
  $mobileNav.on('click', '.nav__list > li.menu-item-has-children > .dropdown-toggle', openSubNav )
}
